@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;600;900&family=Libre+Caslon+Text&display=swap');

:root {
    --navbar-height: 100px;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: var(--navbar-height);
}

body {
    background: #fefefe;
    height: 3000px;
    font-family: 'Libre Caslon Text', serif;
    border: 0;
    padding: 0;
    margin: 0;
    color: #03224e;
}

.site-container {
    max-width: 1920px;
    margin: auto;
    position: relative;
}

.navbar {
    height: var(--navbar-height);
    width: clamp(0px, 100vw, 1920px) !important;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background: #fefefe;
    z-index: 1;
}

.navbar-list {
    margin-right: 20px;
}

.navbar-list a {
    font-size: 1.1em;
    font-family: 'Lato', sans-serif;
    color: #2a282b;
    line-height: var(--navbar-height);
    padding: 0 20px;
    text-decoration: none;
}

.navbar-active {
    text-decoration: underline;
}

.logo {
    margin: 20px 10px;
}

.menu-container {
    position: relative;
}

.hamburger {
    margin: 37px 25px;
    cursor: pointer;
    display: none;
}

.hamburger div {
    width: 35px;
    height: 0;
    border: 3px solid black;
    margin: 2px;
}

#hamburger-list {
    padding: 10px 0;
    position: absolute;
    right: 0px;
    top: var(--navbar-height);
    background: rgba(254, 254, 254, 0.8);
    display: none;
}

#hamburger-list a {
    display: block;
    padding: 10px 70px 10px 20px;
    font-size: 1.1em;
    font-family: 'Lato', sans-serif;
    color: #2a282b;
    line-height: 2.5em;
    text-decoration: none;
    text-align: right;
}

/* @media (max-width: 800px) { */
@media (max-width: 970px) {
    .navbar-list {
        display: none;
    }

    .hamburger {
        display: inline-block;
        margin-right: 40px;
    }
}

.about-us {
    margin-top: var(--navbar-height);
    background-image: url("img/hero-image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    max-height: 1000px;
    width: min(100vw, 1920px);
    max-width: 100%;
    box-sizing: border-box;
}

.orange-header {
    color: #bf6050;
    text-transform: uppercase;
    font-size: 0.9em;
}

#address {
    font-family: 'Lato', sans-serif;
    margin-top: 0px !important;
    margin-bottom: 5px !important;
}

.section h1 {
    font-size: 2.3em;
}

.divider {
    background-color: #bf6050;
    height: 2px;
    margin: auto;
}

.divider-large {
    max-width: 750px;
}

.divider-small {
    max-width: 200px;
}

.section h1,
.orange-header {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    letter-spacing: 2px;
}

.section p {
    margin-top: 50px;
    max-width: 80vw;
    text-align: center;
    display: block;
    margin: 50px auto;
    font-size: 1.5em;
    line-height: 1.5em;
}

#hero-caption {
    max-width: 600px;
    margin-top: 30px;
}

.button {
    display: block;
    background-color: #da6f5c;
    border: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    letter-spacing: 2px;
    color: #fff;
    width: 200px;
    height: 50px;
    font-size: 1.2em;
    border-radius: 7px;
    margin: auto;
    text-decoration: none;
    line-height: 50px;
}

.button-contact {
    margin-top: 25px;
    margin-bottom: 25px;
}

.section {
    text-align: center;
    padding: 60px 35px;
    /* scroll-margin-bottom: 600px; */
}

.light {
    background: #f1f1f1;
}

.dark {
    background: #292728;
    color: #fefefe;
}

.image-section {
    display: flex;
    flex-direction: row;
}

.image-section.odd {
    flex-direction: row;
}

.image-section.even {
    flex-direction: row-reverse;
}

.image-section > * {
    width: 50vw;
    height: 50vw;
    max-width: 1100px;
    max-height: 1100px;
}

.image-1 {
    background: url('img/foto1.jpg') center no-repeat;
    background-size: cover;
}

.image-2 {
    background: url('img/foto2.jpg') center no-repeat;
    background-size: cover;
}

.image-3 {
    background: url('img/foto3.jpg') center no-repeat;
    background-size: cover;
}

.image-4 {
    background: url('img/foto4.jpg') center no-repeat;
    background-size: cover;
}

.image-5 {
    background: url('img/foto5.jpg') center no-repeat;
    background-size: cover;
}

.description {
    text-align: center;
    position: relative;
}

.description-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 0 35px;
}

.description h3 {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    letter-spacing: 2px;
    font-size: 2em;
    margin-bottom: 10px;
}

.description .divider {
    margin-bottom: 15px;
}

.description p {
    width: 25vw;
    max-width: 850px;
    margin: auto;
    font-size: 1.5em;
}

.icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.icon-item {
    color: inherit;
    text-decoration: none;
    font-family: 'Lato', sans-serif;
    letter-spacing: 2px;
    font-size: 1.3em;
    display: block;
    width: 30vw;
    margin: 30px;
}

.icon {
    width: 80px;
    display: block;
    margin: 30px auto; 
}

.icon-item span {
    display: block;
    font-size: 0.8em;
}

#map {
height: 400px;
width: 100%;
}

form {
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: space-between;
}

form section {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 20px 0;
}

.left {
    align-items: flex-start;
}

.right {
    align-items: flex-end;
    margin-top: 20px;
}

form section label {
    font-size: 1.1em;
    margin-bottom: 10px;
    display: block;
    width: 80%;
    text-align: left;
}

.required,
.right label {
    width: 80%;
    text-align: left;
}

form section input {
    width: 80%;
    box-sizing: border-box;
    border-radius: 0;
    border: 0;
    padding: 5px;
    font-size: 1.1em;
    margin-bottom: 20px;
    height: 35px;
}

.required {
    font-size: 0.8em;
    padding-top: 80px;
}

.require-left {
    display: block;
}

.require-right {
    display: none;
}

textarea[name="body"] {
    font-family: 'Libre Caslon Text', serif;
    padding: 10px;
    width: 80%;
    resize: none;
    box-sizing: border-box;
    height: 210px;
    color: #7b7b7d;
}

.button-form {
    margin-top: 60px;
    background-color: #da6f5c;
    color: #fff;
    border-radius: 3px;
    font-weight: 600;
    font-size: 1.2em;
    height: 60px;
}

.grey {
    background: #545253;
    color: #b6b6b6;
    font-family: 'Lato', sans-serif;
}

@media(min-width: 1050px) {
    .grey span {
        display: block;
        width: 80%;
        text-align: left;
    }
}

@media (max-width: 1250px) {
    .section p,
    .description p {
        font-size: 1.0em;
    }
}

@media (max-width: 1050px) {
    .image-section.odd,
    .image-section.even {
        flex-direction: column;
    }
    
    .image-section > * {
        width: 100vw;
        height: 100vw;
        max-width: 100%;
    }

    .description p {
        width: 80vw;
        margin: auto;
    }

    .icons {
        flex-direction: column;
        align-items: center;
    }

    .icon-item {
        width: 90vw;
    }

    form {
        flex-direction: column;
        align-items: center;
    }

    form section {
        width: 80vw;
    }

    .left,
    .right {
        align-items: center;
    }

    .right {
        margin-top: 0;
        padding-top: 0;
    }

    .left {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .required {
        padding-top: 20px;
    }
    
    .require-left {
        display: none;
    }

    .require-right {
        display: block;
    }

    .button-form {
        margin-top: 45px;
    }
}

@media (max-width: 800px) {
    .required,
    .left label,
    .right label,
    textarea[name="body"],
    form section input {
        width: 80vw;
    }
}
